<template>
    <div class="loginView">
        <div class="loginContainer col-6 row gx-0 justify-content-end">
            <div class="col-8 align-self-center">
                <h1 class="">{{ sayHello() }}</h1>
                <b-form @submit="login">
                    <b-form-group>
                        <label :class="errorOuccured()"> {{ $t('login.username') }}</label>
                        <b-form-input v-model="email" type="email" placeholder="Enter email"></b-form-input>
                        <label :class="errorOuccured()">{{ $t('login.password') }}</label>
                        <b-form-input v-model="password" type="password" :placeholder="$t('login.enterPassword')"></b-form-input>
                        <p v-show="error" class="advice">{{ errorMsg }}</p>
                        <div class="spacer l"></div>
                        <div class="row justify-content-between">
                            <div class="passwordReset col align-self-center">
                                <router-link to="/forgotpassword">
                                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.817 14.295c.082-.734.408-1.374.977-1.92l.91-.865c.71-.689 1.209-1.316 1.493-1.881a3.955 3.955 0 0 0 .427-1.803c0-1.4-.438-2.484-1.314-3.251-.876-.767-2.108-1.151-3.695-1.151-1.572 0-2.813.404-3.723 1.213-.91.808-1.372 1.928-1.387 3.358h3.257c.015-.6.19-1.07.522-1.41.334-.34.777-.51 1.331-.51 1.168 0 1.752.632 1.752 1.897 0 .42-.112.818-.337 1.196-.224.378-.675.869-1.353 1.471-.678.603-1.144 1.215-1.398 1.837-.255.621-.382 1.456-.382 2.504h2.188c.387 0 .707-.3.732-.685zm-1.426 5.84c.546 0 .986-.161 1.32-.483.332-.322.499-.73.499-1.224 0-.502-.17-.916-.511-1.241-.34-.326-.777-.489-1.308-.489-.532 0-.968.163-1.309.489-.34.325-.51.74-.51 1.24 0 .495.166.903.499 1.225.333.322.773.483 1.32.483z" fill="#0CBA4A" fill-rule="nonzero"/>
                                    </svg>
                                    {{ $t('login.forgotPassword') }}
                                </router-link>
                            </div>    
                            <div class="col">
                                <b-button class="loginButton" type="submit" variant="primary">{{ $t('login.login') }}</b-button>
                            </div>    
                        </div>    
                    </b-form-group>
                </b-form>
            </div>    
        </div> 
        <div :class="'logoContainer col-6 backgroundImage' + backgroundNumber">
            <img src="../assets/img/logo_pooling.svg">
        </div>
    </div>
</template>
<script>
    import DataService from '@/services/StrapiService'

    export default {
        name: 'Login',
        
        data() {
            return {
                email: '',
                password: '',
                error: false,
                errorMsg: this.$t('login.loginError'),
                strapiUrl: DataService.getServerUrl(),
                backgroundNumber: 0
            }
        },
        created () {
            this.getRandomBackgroundImgae()
        },
        methods: {
            sayHello() {
                var currentTime = new Date()
                var hours = currentTime.getHours()
                var text = this.$t('login.greetingEvening')
                if ( hours > 0 & hours <= 10 ) {
                    text = this.$t('login.greetingMorning')
                }
                if ( hours > 10 & hours <= 17 ) {
                    text = this.$t('login.greetingDefault')
                }
                return text
            },
            getRandomBackgroundImgae() {
                this.backgroundNumber =  Math.floor(Math.random() * (13 - 1 + 1) + 1)
            },
            errorOuccured() {
                if (this.error) {
                    return "error"
                }
            },
            async login(e) {
                window.localStorage.clear()
                e.preventDefault()
                await DataService.login(this.email, this.password).then(async (response) =>  {
                    this.$store.commit('updateJwt', response.data.jwt)
                    await DataService.getUserData().then((response) => {
                        this.$store.commit('updateUserData', response.data)
                        if (response.data.language != null) {
                            this.$i18n.locale = response.data.language.languageKey
                        }
                    }).catch((err) => {
                        console.log(err)
                    })

                    DataService.getBottleSizes().then((response) => {
                        this.$store.commit('setBottleSizes', response.data)
                    }).catch((err) => {
                        console.log(err)
                    })
                    DataService.getRatingAgencies().then((response) => {
                        this.$store.commit('setRatingAgencies', response.data)
                    }).catch((err) => {
                        console.log(err)
                    })
                    DataService.getCurrencies().then((response) => {
                        this.$store.commit('setCurrencies', response['data'].data)
                    }).catch((err) => {
                        // handle error
                        console.log(err)
                    })
                    DataService.getLanguages().then((response) => {
                        this.$store.commit('setLocales', response['data'])
                    }).catch((err) => {
                        console.log(err)
                    })
                    this.$router.push('/')
                }).catch((err) => {
                    console.log(err)
                    this.error = true
                    this.password = ''
                })
            },
        }
    }
</script>
<style scoped>
</style>